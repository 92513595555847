* {
    /* font-family: Optima; */
    font-family: 'Shippori Mincho', serif;
    justify-content: center;
    background-color: (0, 0, 0, 0.5);
    box-sizing: border-box;
    margin: 0;
}

.openModalBtn {
    border: black;
    background-color: black;
}

/* Videos */
.reactplayer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* box-shadow: 0px 0px 50px 0 rgba(255, 255, 255, 0.5); */

}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: black;
}

/* Scroll style */
div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #f8fbff;
    border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
    background-color: #9fa9bd;
    border-radius: 5px;
}

div::-webkit-scrollbar {
    width: 0.5em;
    border-radius: 5px;
}

/* Modal */

.modalBackground {
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    color: black;
    padding-top: 24px;
    top: 0px;
    overflow-y: auto;
}

.responsive {
    width: 100%;
    height: auto;
    /* box-shadow: 0px 0px 50px 0 rgba(255, 255, 255, 0.5); */

}

.titleCloseBtn {
    position: fixed;
    top: 4%;
    left: 90%;
    border: transparent;
    background-color: transparent;
}

.modalBackground .modalContainer .titleCloseBtn button:hover {
    text-shadow: 0px 0px 10px white, 0 0px 10px blue, 0 0px 10px blueviolet;
    transition: text-shadow 0.5s ease-in-out;
    border: transparent;
    background-color: transparent;

}

.titleCloseBtn button {
    font-family: 'Krub', sans-serif;
    background-color: transparent;
    font-size: 40px;
    cursor: pointer;
    border: transparent;
    background-color: transparent;
    color: white;
}

.modalContainer {
    flex: 50%;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-align: left;
    color: white;
    margin: auto;
    width: 50%;
}

/* Navigation */

.nav {
    position: fixed;
    color: white;
    top: 0px;
    width: 100%;
    background-color: black;
    /* z-index: 1; */
    height: 7vh;
    left: 0;
    box-shadow: 0px 7px 7px 0 rgba(0, 0, 0, 1);
}

.nav .nav__brand {
    font-size: 24px;
    margin-top: 3vh;
    margin-left: 2vh;
    box-shadow: 0px 7px 7px 0 rgba(0, 0, 0, 1);


}

/* Main Container */

.description {
    font-family: Optima;
    font-style: none;
    color: white;
    justify-content: left;
    font-size: 24px;
}

.main-container button:hover {
    cursor: pointer;
}

.main-container button {
    display: inline-block;
    text-align: center;
    margin-top: 0px;
    font-size: 24px;
    margin-left: 2vh;
    padding-bottom: 0px;
}

.App {
    padding-top: 105px;
    outline: none;
}

button {
    background-color: black;
    appearance: auto;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: white;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    margin: 0em;
    padding: 0px;
    border-width: 0px;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
}

.main-container button:hover {
    text-shadow: 0px 0px 10px white, 0 0px 5px blue, 0 0px 5px blueviolet;
    transition: text-shadow 0.5s ease-in-out;
}

.nav button:hover {
    cursor: pointer;
    text-shadow: 0px 0px 10px white, 0 0px 5px blue, 0 0px 5px blueviolet;
    transition: text-shadow 0.5s ease-in-out;
}

/* Text */

.nav h1 {
    font-weight: 100;
    font-size: 24px;
}

.h1 {
    display: inline-block;
    text-align: center;
    margin-top: 0px;
    font-size: 24px;
    margin-left: 2vh;
    padding-bottom: 24px;
    font-style: italic;
}

h2 {
    text-decoration: none;
    font-size: 20px;
    font-style: italic;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 800;
}

h3 {
    text-decoration: none;
    font-size: 18px;
    font-style: italic;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
}

h4 {
    text-decoration: none;
    font-size: 14px;
    font-style: italic;
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
}

h5 {
    text-decoration: none;
    font-size: 10px;
    font-style: italic;
    vertical-align: super;
    display: inline-block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
}

a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-style: italic;
    text-decoration: underline .5px;
    text-underline-offset: 6px;
}

p {
    text-decoration: none;
    color: white;
    font-size: 18px;
}

a:hover {
    cursor: pointer;
    text-shadow: 0px 0px 10px white, 0 0px 5px blue, 0 0px 5px blueviolet;
    transition: text-shadow 0.5s ease-in-out;
}

footer p {
    display: block;
    position: fixed;
    text-decoration: none;
    color: white;
    font-size: 12px;
    padding: 0px;
    margin-left: 2vh;
    bottom: 0;
    padding-bottom: 26px;
}

.inverse {
    display: inline-block;
    transform: skew(15deg, 0deg);
    -ms-transform: skew(15deg, 0deg);
    /* IE 9 */
    -webkit-transform: skew(15deg, 0deg);
    /* Safari and Chrome */
    -o-transform: skew(15deg, 0deg);
    /* Opera */
    -moz-transform: skew(15deg, 0deg);
    /* Firefox */
}

/* Margins */

footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: black !important;
    z-index: 1;
    height: 7vh;
    left: 0;
    box-shadow: 0px -7px 7px 0px rgb(0, 0, 0, 1);
}

header {
    position: fixed;
    top: 1vh;
    width: 100%;
    z-index: 1;
    height: 7px;
    left: 0;
    box-shadow: 0px 7px 7px 0 rgb(0, 0, 0);
}


@media only screen and (max-width: 1000px) {

    .modalContainer {
        font-size: 16px;
        margin: auto;
        width: 100%;
    }

    .modalBackground {
        padding-top: 18px;
        opacity: .99;
    }

    .titleCloseBtn {
        border: transparent;
        background-color: transparent;
        position: absolute;
        left: 90%;
        top: 4%;
        z-index: 1;
    }

    .titleCloseBtn button {
        font-weight: bold;
        text-shadow: 0px 0px 15px white, 0px 0px 15px blue, 0px 0px 15px blueviolet;
    }

    /* .responsive {
        box-shadow: 0px 0px 16px 0 rgba(255, 255, 255, 0.5);
    }

    .react-player {
        box-shadow: 0px 0px 16px 0 rgba(255, 255, 255, 0.5);
    } */

    /* 2  */
    .nav {
        height: 5vh;
    }

    footer {
        height: 5vh;
    }

    footer p {
        padding-bottom: 32px;
    }

    header {
        top: 1vh;
    }

}

@media only screen and (max-width: 850px) {

    .titleCloseBtn {
        border: transparent;
        background-color: transparent;
        position: absolute;
        left: 87%;
        top: 5.4%;
        z-index: 1;
    }

    .titleCloseBtn button {
        font-size: 25px;
        border: transparent;
        background-color: transparent;
        font-weight: bold;
        text-shadow: 0px 0px 15px white, 0px 0px 15px blue, 0px 0px 15px blueviolet;
    }

    /* 2 */
    .nav {
        height: 7vh;
    }

    footer {
        height: 3vh;
    }

    footer p {
        padding-bottom: 2%;
    }

    a {
        font-style: italic;
        text-decoration: underline .5px;
        text-underline-offset: 6px;
    }

    div::-webkit-scrollbar {
        width: 0.05em;
        border-radius: 0px;
    }

    div::-webkit-scrollbar-thumb {
        background-color: #9fa9bd;
        border-radius: 1px;
    }

    div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #f8fbff;
        border-radius: 1px;
    }
}


.ReactModal__Overlay {
    opacity: 0;
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}